@import 'flowbite';

@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  background-color: #2c2c54;
}

/* Gradient Range Slider */
#range-slider-gradient {
  height: 24px;
  border-radius: 12px;
  background: #353644;
}

#range-slider-gradient .range-slider__thumb {
  border: 6px solid #fff;
  box-sizing: border-box;
}

#range-slider-gradient .range-slider__thumb[data-lower] {
  background: #0073e6;
}

#range-slider-gradient .range-slider__thumb[data-upper] {
  background: #ee2c2c;
}

#range-slider-gradient .range-slider__range {
  background: linear-gradient(to right, #0073e6, #ee2c2c);
  background-size: 200% 100%;
  background-position: 50% 0;
}

#range-slider-gradient .range-slider__range[data-active],
#range-slider-gradient
  .range-slider__thumb[data-active]
  ~ .range-slider__range {
  animation: move-bg 0.75s infinite linear;
}

@font-face {
  font-family: 'Fancy';
  src: url('./assets/PlaywriteIS-VariableFont_wght.ttf');
}

@font-face {
  font-family: 'Sans';
  src: url('./assets/Outfit-VariableFont_wght.ttf');
}

@font-face {
  font-family: 'SansBold';
  src: url('./assets/SpaceMono-Bold.ttf');
}

@font-face {
  font-family: 'SansItalic';
  src: url('./assets/SpaceMono-Italic.ttf');
}

@font-face {
  font-family: 'Hand';
  src: url('./assets/Calligraffitti-Regular.ttf');
}

@font-face {
  font-family: 'Thick';
  src: url('./assets/Staatliches-Regular.ttf');
}


.primary-button {
    background-color: #E0B1F1;
    color: black;
    font-size: 1rem;
    padding: 8px 16px;
    transition: background-color 0.3s ease;
}

.primary-button:hover {
    background-color: #D8A8E8;
}

.primary-button:disabled {
    background-color: #cccccc;
    color: #666666;
    cursor: not-allowed;
}

/* Add shimmer animation for skeleton loading */
@keyframes shimmer {
  0% {
    background-position: 200% 0;
  }
  100% {
    background-position: -200% 0;
  }
}

.animate-shimmer {
  animation: shimmer 1.5s infinite linear;
}

/* Add shine animation for the no minimum spend badge */
@keyframes shine {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(100%);
  }
}

.animate-shine {
  animation: shine 3s infinite ease-in-out;
}
